import React from "react"
import BrandFooter from "./BrandFooter/BrandFooter"
import BrandHeader from "./BrandHeader/BrandHeader"

const ThanksBrandPage = () => {
  return (
    <>
      <BrandHeader />
      <div
        className="ctaBlock"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <div className="elva ctaBlock_content">
          <h2 className="text-4xl ctaBlock_text">Tack</h2>
          <p className="mb-6 mt-4 color-black">
            Tack för ditt meddelande. Vi återkommer så snart vi kan.
          </p>
          <button
            onClick={() => {
              window.location.pathname = "/"
            }}
            className="ctaBlock_btn rounded table font-semibold px-9 py-4 text-center mx-auto my-2.5 leading-none"
          >
            Till Startsidan
          </button>
        </div>
      </div>
      <BrandFooter />
    </>
  )
}

export default ThanksBrandPage
